.App {
    text-align: center;
}

.App-logo {
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(1080deg);
    }
}

.strength-meter {
    position: relative;
    height: 3px;
    background: #DDD;
    margin: 7px 0;
    border-radius: 2px;
}

.strength-meter:before,
.strength-meter:after {
    content: '';
    height: inherit;
    background: transparent;
    display: block;
    border-color: #FFF;
    border-style: solid;
    border-width: 0 6px 0;
    position: absolute;
    width: calc(20% + 6px);
    z-index: 10;
}

.strength-meter:before {
    left: calc(20% - 3px);
}

.strength-meter:after {
    right: calc(20% - 3px);
}

.strength-meter-fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    border-radius: inherit;
    transition: width 0.5s ease-in-out, background 0.25s;
}

.strength-meter-fill[data-strength='0'] {
    width: 20%;
    background: darkred;
}

.strength-meter-fill[data-strength='1'] {
    width: 40%;
    background: orangered;
}

.strength-meter-fill[data-strength='2'] {
    width: 60%;
    background: orange;
}

.strength-meter-fill[data-strength='3'] {
    width: 80%;
    background: yellowgreen;
}

.strength-meter-fill[data-strength='4'] {
    width: 100%;
    background: green;
}

.bg-w0 {
    background-color: #ffffff !important;
}

.bg-w-1 {
    background-color: #f7f7f7;
}

.bg-w-2 {
    background-color: #eeeeee;
}

.bg-w-3 {
    background-color: #e6e6e6;
}

.notes-header {
    border-bottom: 1px solid #eeeeee;
}

.note-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    vertical-align: center;
}

.new-note {
    display: block;
    float: left;
    margin-right: 2pt;
    margin-bottom: 2pt;
    height: 3em;
}

.invoice-list-part {
    border-bottom: 1px solid #eeeeee;
}

table.propTable th {
    font-weight: normal ! important;
    text-align: right;
}

table.propTable td {
    font-weight: bold ! important;
}

.thCena {
    vertical-align: middle;
    padding-left: 4px;
    text-align: center;
    padding-top: 16px;
    background: #f2f2f2;
    padding-right: 4px;
}

.tdHelper {
    display: inline-block;
}

.tdCena {
    display: inline-block;
    transform: rotate(270deg);
    transform-origin: 100% 100%;
    font-size: 120%;
}

.ieTable {
    table-layout: fixed;
}

table.ieTable th.field {
    text-align: right;
    font-weight: normal ! important;
}

.ieTable td {
    font-weight: bold ! important;
}

.insuranceHeadSpan {
    display: inline-block
}

.rating {
    border: 1px solid grey;
    color: rgb(65, 64, 64);
    display: inline-block;
    text-align: center;
    padding: 0.3em;
    width: 3.8rem;
}

.limit-active {
    background-color: #a0c75c !important;
}

.limit-inactive {
    background-color: #ddd9d9 !important;
}


/* Lucka */

.beBigger {
    font-size: 110%;
    font-weight: 500;
}

.barvy {
    padding-right: 0.2em;
    padding-left: 0.2em;
    border-radius: 6px;
    text-align: center;
    margin-left: 0.1em;
}

.beSmaller {
    padding: 0.1em 0.6em !important;
}

.list-group.smaller .list-group-item {
    padding: 0.2em;
}

.card-header.smaller {
    padding: 0.7em;
}


/* Lucka -  header */

.beBiggest {
    font-size: 190%;
    font-weight: 700;
}

.go-together2 {
    padding-left: 0.4em;
}

.go-together {
    padding-right: 0;
}

.look-good {
    padding: 0.3em 1em;
}

.barvy2 {
    /** ** **/
    text-align: center;
    padding: 0.75em 0.5em;
}

.less-padding {
    /** ** **/
    padding: 0em 0.9em;
}

.beBigger2 {
    /** ** **/
    /* font-size: 110%; */
    font-weight: 500;
    padding: 0.75em 0.5em;
}

.beGray {
    background: rgb(211, 208, 208) !important;
}

.beGray2 {
    background: rgb(230, 226, 226) !important;
}

/*beGray 3-4 are used in statistics tables */

.beGray3 {
    background: rgb(223, 216, 216);
}

.beGray4 {
    background: rgb(243, 239, 239) !important;
}

.beGray5 {
    background: rgb(247, 244, 244) !important;
}

.beGray6 {
    background: #eaeaea !important;
}

tr.propTable2 th {
    font-weight: normal ! important;
    text-align: right;
}

tr.propTable2 td {
    font-weight: 600 ! important;
}

tr.trhref:hover td {
    color: #666;
}

.list-group-item.limit-active {
    border-left: none;
    border-right: none;
    border-top: none;
}

.list-group-item.limit-inactive {
    border-left: none;
    border-right: none;
    border-top: none;
}

button.limit-inactive {
    margin-top: 4px;
    border: none;
}

button.limit-active {
    margin-top: 4px;
    border: none;
}

.leftBorder {
    border-left: 1px solid rgb(146, 142, 142);
}

.p-m {
    padding: 0.6em;
    margin-bottom: 1.5em;
}

.noMargin {
    margin: 0;
}

.noStyle {
    color: rgb(25, 17, 143);
    text-align: left;
    display: inline-block;
    margin-right: 1.5rem;
}

.noStyle:hover {
    text-decoration: none;
    color: rgb(90, 90, 90);
}

.nav-link.noStyle {
    padding: 0px;
}

.rating-img {
    padding-bottom: 5.5px;
    padding-left: 1px;
}

.btn-info.buttonek {
    background-color: #54c7d8;
    border-color: #62b7c4;
}

.btn-info.dropdown-toggle.buttonek {
    background-color: #4cb1c0;
    border-color: #62b7c4;
}

.btn-info.buttonek:focus {
    background-color: #4abbcc;
    border-color: #62b7c4;
}

.show>.btn-info.dropdown-toggle.buttonek {
    background-color: #4abbcc;
    border-color: #62b7c4;
}

.sto .btn {
    width: 100%;
}

.beGrayButton .btn {
    background: rgb(231, 228, 228);
}

.smaller2 .list-group-item {
    padding: .37rem .6rem;
}

.stin {
    box-shadow: 0 1.5px 3px 0 rgba(58, 58, 58, 0.2);
    /* margin-top: .3rem; */
    margin-left: .3rem;
    margin-right: .3rem;
}

.stin2 {
    box-shadow: 0 3px 6px 0 rgba(12, 11, 11, 0.2);
}

.graphRed {
    background-color: rgba(236, 120, 118, 0.5);
    padding: 0 .1rem;
    border: 3px solid #f1433f;
}

.graphGreen {
    background-color: rgba(40, 167, 69, 0.1);
    padding: 0 .1rem;
    border: 3px solid #28a745;
}

.graphGrey {
    background-color: rgba(225, 225, 225, 0.25);
    padding: 0 .1rem;
    border: 3px solid rgba(128, 128, 128, 0.5);
}

.graphYellow {
    background-color: rgba(204, 217, 46, 0.01);
    padding: 0 .1rem;
    border: 3px solid rgb(255, 191, 0);
}

.rating_new {
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    padding: 0.3em;
    width: 3.8rem;
    color: white;
}

.rating_new2 {
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    padding: 0.01em;
    width: 11rem;
    /* height: 50.8rem; */
    color: white;
}

.cross-out {
    text-decoration: line-through solid 2px rgb(114, 112, 112);
}

.my-alert-red {
    color: #721c24;
    background-color: #f8d7da;
    border-radius: .25rem;
    padding: .5rem;
    margin-top: 10px;
}

.my-alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-radius: .25rem;
    padding: .5rem;
    margin-top: 10px;
}

.style-big {
    font-size: 125%;
    font-weight: 680;
}

.mdStyle table,
.mdStyle td,
.mdStyle th {
    padding: 6px;
}

.mdStyle tr {
    border: 1px solid black;
}

.mdStyle img {
    max-width: 75%;
}

.mdStyle blockquote {
    background-color: #e4fbfd;
    padding: 8px 6px 1px 6px;
    max-width: 85%;
    margin: auto;
}

.beActive .nav-link.active {
    color: #0162ca;
    font-weight: bold;
}

.mW80 {
    min-width: 80px;
}

.mW100 {
    min-width: 100px;
}

.sticky {
    position: sticky !important;
    left: 0;
    top: 0;
    z-index: 1;
}

.pt55 {
    padding-top: 58px !important;
}

.repairFontWeight .th {
    font-weight: normal !important;
}

.striped-2-rows.table tr:nth-child(4n-1),
.striped-2-rows.table tr:nth-child(4n) {
    background: rgb(241, 240, 240);
}

.light-grey-bg {
    background-color: #f8f9fa;
}

.bg-red {
    background-color: #f8d7da;
}

.noPadding .card-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.myHover {
    color: rgb(143, 143, 143);
    font-weight: 800;
}

.myHover:hover {
    color: black;
    font-weight: 800;
}

.closerStyle .close {
    padding-top: 5px !important;
}


/*globálně jemnější headery tabulek*/

.table thead th {
    font-weight: 600;
}

.myShadow {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: none !important;
}

body {
    background-color: #f6f6f6 !important;
}

.bg-blue {
    background-color: rgb(144, 251, 255)
}

.bg-yellow {
    background-color: rgb(247, 247, 104)
}

.noBorder {
    border: white;
}

.width7em {
    width: 7em;
}

.bolder {
    font-weight: 550;
}

.blueish {
    color: #004997;
}

.blueish:hover {
    text-decoration: underline;
}


/*
.colored {
    border-color: rgb(5, 149, 89) !important;
    color: rgb(5, 146, 99) !important;
    font-weight: bold;
}
*/


.progress-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 30px;
    max-width: 100%;
}

.myBefore {
    /*content: "";*/
    background-color: #a1aeb3;
    position: absolute;
    top: 18px;
    transform: translateY(-50%);
    height: 4px;
    z-index: 0;
}

.progressz {
    z-index: 1;
}

.circle {
    background-color: #fff;
    color: #b2bec3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: larger;
    font-weight: 700;
    margin: auto;
    padding-top: 2px;
}

.myGrey {
    background-color: #ebe7e7 !important;
    color: black !important;
    border-color: #f5f2f2 !important;
}

.myGrey:hover {
    background-color: #e2e0e0 !important;
    color: black !important;
    border-color: #e2e0e0 !important;
}

.bg-info-light {
    background-color: #bdf2fa;
}

.toDoLink {
    text-decoration: none;
    color: inherit;
}

.toDoLink:hover {
    text-decoration: underline;
    color: inherit;
}

.rotate90 {
    transform: rotate(270deg);
    margin-top: 300px;
}